import React, { Component } from 'react'
import './Thumb.css'

export default class Thumb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
    this.keydown = this.keydown.bind(this)
  }
  keydown(e) {
    if (e.code == "Escape") {
      this.setState({active: false})
    }
  }
  componentDidUpdate(prevprops, prevstate, snapshot) {
    if (prevstate.active && !this.state.active) {
      document.removeEventListener("keydown", this.keydown)
    } else if (!prevstate.active && this.state.active) {
      document.addEventListener("keydown", this.keydown)
    }
  }
  render() {
    return <div className="thumb">
      <a href={this.props.src} className="no-outline" onClick={(e)=>{e.preventDefault()}}>
        <img className="thumb" src={this.props.src} title={this.props.title} alt={this.props.alt} onClick={(e)=>{
          e.preventDefault()
          this.setState({active: !this.state.active})
        }}/>
      </a>
      {this.state.active && <div className="thumb-overlay" onClick={(e)=>{
        if (e.currentTarget == e.target)
          this.setState({active: !this.state.active})
      }}>
        <a href={this.props.src} className="no-outline" onClick={(e)=>{e.preventDefault()}}>
          <img src={this.props.src} title={this.props.title} alt={this.props.alt} onClick={(e)=>{
            e.preventDefault()
            this.setState({active: !this.state.active})
          }}/>
        </a>
      </div>}
    </div>
  }
}