import interactive from './assets/interactive.png'
import kopunch from './assets/kopunch.png'
import maestro from './assets/maestro.png'
import digipen from './assets/digipen.jpg'
import mfmmfy from './assets/mfmmfy.jpg'
import nono from './assets/nono.png'
import rpg from './assets/rpg.png'
import bellyup from './assets/bellyup.jpg'
import convolver from './assets/convolver.png'
import convolver_dl from './assets/Convolver.vst3'
import Thumb from './components/Thumb'
import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <h1>Ott Smithson</h1>
          Programming, Music, Programmer Art
        </p>
      </header>
      <div className="project">
        <Thumb src={bellyup} alt="Belly Up (January 2021 - April 2021)"/>
        <div className="project-content">
          <h2>Belly Up</h2>
          <div className="date">January 2021 - April 2021</div>
          <p>
            Belly Up is a single-player real-time strategy game built in one semester on the Unity engine. My contributions included 
            a multithreaded voxel terrain system, WWise implementation, and some 2d artwork. The terrain system makes use of Unity's
            Data Oriented Technology Stack to avoid race conditions and includes simulations for falling sand and water.
          </p>
        </div>
        <div className="spacer"/>
      </div>
      <div className="project">
        <iframe className="thumb" width="560" height="315" src="https://www.youtube-nocookie.com/embed/75dLojmKmjY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
        <div className="project-content">
          <h2>Sublimination</h2>
          <div className="date">September 2019 - December 2020</div>
          <p>
            Sublimination is a custom engine, 2-player first person shooter. My contributions to this team project
            included a custom audio engine, soundtrack, level design, and player movement physics solver. Level design 
            was done in Valve's Hammer editor. Geometry was imported with a VMF to FBX program, and entities were
            imported using a custom command-line utility. Textures were created using a combination of photos and Blender,
            for normal maps. I used Bitwig Studio to make the soundtrack, and Reaper to design the sound effects using stock libraries.
          </p>
        </div>
        <div className="spacer"/>
      </div>
      <div className="project">
        <Thumb src={digipen} alt="DigiPen Lobby (June 2020 - August 2020)"/>
        <div className="project-content">
          <h2>DigiPen Lobby</h2>
          <div className="date">June 2020 - August 2020</div>
          <p>
            During the summer of 2020, I created a to-scale replica of the first floor of DigiPen Institute of Technology's campus.
            The recreation was used for AVAR 2020's virtual conference in AltSpace VR, and could potentially be used
            for future DigiPen outreach programs.
          </p>
          <a href="https://www.digipen.edu/showcase/news/2020-aes-avar-conference-heads-to-virtual-reality-with-digipen">https://www.digipen.edu/showcase/news/2020-aes-avar-conference-heads-to-virtual-reality-with-digipen</a>
          </div>
        <div className="spacer"/>
      </div>
      <div className="project">
        <Thumb src={interactive} alt="Interactive (April 2020 - present)"/>
        <div className="project-content">
          <h2>Interactive</h2>
          <div className="date">April 2020 - present</div>
          <p>
            Interactive is a collection of React components designed to communicate with each other automatically, 
            with no user-side state management required. The components can be used to write textbook interactives declaritavely.
            It was written to reduce costs at my workplace.
          </p>
        </div>
        <div className="spacer"/>
      </div>
      <div className="project">
        <Thumb src={rpg} alt="rpg (January 2020 - May 2020)"/>
        <div className="project-content">
          <h2>rpg</h2>
          <div className="date">January 2020 - May 2020</div>
          <p>
            rpg was a foray into Rust and 3D rendering. I had intended to make a game out of it, but I got lost in the weeds of engine programming. 
            What I learned: Entity component systems are about data, not objects. Rust's borrow checker prescribes a 'correct' way to structure your program.
            User interface layout algorithms are complex. Serialization is best done manually.
          </p>
          <a href="https://gitlab.com/ottworks/rpg">https://gitlab.com/ottworks/rpg</a>
        </div>
        <div className="spacer"/>
      </div>
      <div className="project">
        <Thumb src={nono} alt="nono.cpp (January 2020)"/>
        <div className="project-content">
          <h2>nono.cpp</h2>
          <div className="date">January 2020</div>
          <p>
            nono.cpp is a quick <a href="https://en.wikipedia.org/wiki/Nonogram">nonogram</a> solver I wrote over winter break. 
            The solver is iterative, however it is not exhaustive - progress can only be made if there is a certain next step.
          </p>
          <a href="https://gitlab.com/ottworks/nono.cpp">https://gitlab.com/ottworks/nono.cpp</a>
        </div>
        <div className="spacer"/>
      </div>
      <div className="project">
        <Thumb src={kopunch} alt="kopunch (June 2019 - present)"/>
        <div className="project-content">
          <h2>kopunch</h2>
          <div className="date">June 2019 - present</div>
          <p>
            kopunch is a custom React frontend for the Knockout.chat forums. The design is an homage to vBulletin forums of old and an era before mobile-first web design.
            I use the site every day and keep it maintained.
          </p>
          <a href="https://gitlab.com/ottworks/kopunch">https://gitlab.com/ottworks/kopunch</a>
        </div>
        <div className="spacer"/>
      </div>
      <div className="project">
        <Thumb src={mfmmfy} alt="music for me // music for you (June 2018)"/>
        <div className="project-content">
          <h2>music for me // music for you</h2>
          <div className="date">June 2018</div>
          <p>
            music for me // music for you is my debut album. It encompasses a lot of my highschool years leading up to college.
          </p>
          <a href="https://ottworks.bandcamp.com/album/music-for-me-music-for-you">https://ottworks.bandcamp.com/album/music-for-me-music-for-you</a>
        </div>
        <div className="spacer"/>
      </div>
      <div className="project">
        <Thumb src={maestro} alt="maestro (September 2015 - September 2016)"/>
        <div className="project-content">
          <h2>Maestro</h2>
          <div className="date">September 2015 - September 2016</div>
          <p>
            Maestro is an admin mod for Garry's Mod, written in Lua. It aims to provide a similar feature set to ULX
            (the leading Garry's Mod admin mod), in a slimmer and more cohesive package. As of this writing, Maestro
            is in use by over 8000 players.
          </p>
          <a href="https://github.com/ottworks/maestro">https://github.com/ottworks/maestro</a>
        </div>
        <div className="spacer"/>
      </div>
    </div>
  );
}

export default App;
